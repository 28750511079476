import { parse } from "date-fns";
import { USERS } from "@/constants";
import { formatScope } from "@/filters";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { i18n } from "@/main";

export const companiesTemplate = {
  name: {
    label: "Nom de l'entreprise",
    required: true,
    type: String,
  },
  siren: {
    label: "Siren",
    required: true,
    type: String,
  },
  lastName: {
    label: "Nom du dirigeant",
    required: false,
    type: String,
  },
  firstName: {
    label: "Prénom du dirigeant",
    required: false,
    type: String,
  },
  email: {
    label: "Email",
    required: false,
    type: String,
  },
  tel: {
    label: "Numréo de téléphone",
    required: false,
    type: String,
  },
  url: {
    label: "URL",
    required: false,
    type: String,
  },
  adresse: {
    label: "Adresse",
    required: false,
    type: String,
  },
  city: {
    label: "Ville",
    required: false,
    type: String,
  },
  codepostal: {
    label: "Code postal",
    required: false,
    type: String,
  },
};

export const employeesTemplate = {
  firstname: {
    label: "Prénom",
    required: true,
    type: String,
  },
  lastname: {
    label: "Nom",
    required: true,
    type: String,
  },
  socialSecurityNumber: {
    label: "Numéro de sécurité sociale",
    required: true,
    type: String,
  },
  email: {
    label: "Email",
    required: true,
    type: String,
  },
  birthday: { label: "Date de naissance", required: false, type: Date },
  gender: { label: "Sexe", required: false, type: String },
  city: { label: "Ville", required: false, type: String },
  country: { label: "Pays de naissance", required: false, type: String },
  department: { label: "Département", required: false, type: String },
  hireDate: { label: "Date d'embauche", required: false, type: Date },
  contractType: {
    label: "Type de contrat",
    required: false,
    type: String,
  },
  trialPeriod: {
    label: "Durée de la période d'essai",
    required: false,
    type: Number,
  },
  endDate: {
    label: "Date de fin du contrat",
    required: false,
    type: Date,
  },
  salary: { label: "Salaire", required: false, type: Number },
};

export const adminUserTemplate = {
  lastname: {
    label: "Nom",
    required: true,
    type: String,
  },
  firstname: {
    label: "Prénom",
    required: true,
    type: String,
  },
  email: {
    label: "Email",
    required: true,
    type: String,
  },
  telportable: {
    label: "Numéro de téléphone portable",
    required: false,
    type: String,
    validation: tels => {
      let length = 16;
      const isValid = tels.length <= length;
      return {
        error: !isValid,
        message: isValid ? "" : i18n.t("import.tel-validate"),
      };
    },
  },
  telfixe: {
    label: "Numéro de téléphone fixe",
    required: false,
    type: String,
    validation: tels => {
      let length = 16;
      const isValid = tels.length <= length;
      return {
        error: !isValid,
        message: isValid ? "" : i18n.t("import.tel-validate"),
      };
    },
  },
  adresse: {
    label: "Adresse",
    required: false,
    type: String,
  },
  ville: {
    label: "Ville",
    required: false,
    type: String,
  },
  codepostal: {
    label: "Code postal",
    required: false,
    type: String,
    validation: code => {
      let length = 5;
      const isValid = code.length === length || code.length === 0;
      return {
        error: !isValid,
        message: isValid ? "" : i18n.t("import.codepostal-validate"),
      };
    },
  },
  statut: {
    label: "Type d'utiliasateur",
    required: true,
    type: String,
    format: user => {
      return formatScope(user.scope);
    },
  },
};

export const userTemplate = {
  lastname: {
    label: "Nom",
    required: true,
    type: String,
  },
  firstname: {
    label: "Prénom",
    required: true,
    type: String,
  },
  email: {
    label: "Email",
    required: true,
    type: String,
  },
  adresse: {
    label: "Adresse",
    required: false,
    type: String,
  },
  ville: {
    label: "Ville",
    required: false,
    type: String,
  },
  codepostal: {
    label: "Code postal",
    required: false,
    type: String,
    validation: code => {
      if (code) {
        let length = 5;
        const isValid = code.length === length || code.length === 0;
        return {
          error: !isValid,
          message: isValid ? "" : i18n.t("import.codepostal-validate"),
        };
      }
      return {
        error: false,
        message: "",
      };
    },
  },
  telfixe: {
    label: "Numéro de téléphone fixe",
    required: false,
    type: String,
    validation: tels => {
      if (tels) {
        let length = 16;
        const isValid = tels.length <= length;
        return {
          error: !isValid,
          message: isValid ? "" : i18n.t("import.tel-validate"),
        };
      }
      return {
        error: false,
        message: "",
      };
    },
  },
  telportable: {
    label: "Numéro de téléphone portable",
    required: false,
    type: String,
    validation: tels => {
      if (tels) {
        let length = 16;
        const isValid = tels.length <= length;
        return {
          error: !isValid,
          message: isValid ? "" : i18n.t("import.tel-validate"),
        };
      }
      return {
        error: false,
        message: "",
      };
    },
  },
  statut: {
    label: "Type d'utiliasateur",
    required: true,
    type: String,
  },
};

export const downloadExcel = (data, columns, title, fileName) => {
  const wb = XLSX.utils.book_new();

  wb.Props = {
    Title: title,
    Author: "MEEP",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Feuille 1");

  const exportData = data.map(user => {
    const data = {};
    Object.keys(columns).forEach(key => {
      if (typeof columns[key].format === "function") {
        data[columns[key].label] = columns[key].format(user);
      } else {
        data[columns[key].label] = user[key];
      }
    });
    return data;
  });

  wb.Sheets["Feuille 1"] = XLSX.utils.json_to_sheet(exportData);

  /* bookType can be any supported output type */
  var wopts = { bookType: "xlsx", bookSST: false, type: "array" };

  var wbout = XLSX.write(wb, wopts);

  /* the saveAs call downloads a file on the local machine */
  saveAs(new Blob([wbout], { type: "application/octet-stream" }), fileName);
};

export const buildObject = (excelData, columns, type = "none") => {
  const result = {};
  const errors = [];
  const nullKey = ["codepostal", "birthday", "hireDate", "endDate"];
  const zeroKey = ["salary", "trialPeriod"];
  let isValid = true;

  Object.keys(columns).forEach(key => {
    const database = columns[key];
    if (typeof database.validation === "function") {
      const validation = database.validation(excelData[columns[key].label]);
      if (validation.error) {
        errors.push(validation.message);
        isValid = false;
        return;
      }
    }
    if (database.required && !excelData[columns[key].label]) {
      isValid = false;
      if (type === "company") {
        errors.push(
          i18n.t("import-companies.missing", { label: columns[key].label })
        );
        return;
      } else if (type === "user" || type === "employee") {
        errors.push(
          i18n.t("import-users.missing", { label: columns[key].label })
        );
        return;
      } else {
        errors.push(`Le champ de "${columns[key].label}" est obligatoire`);
        return;
      }
    }
    if (key === "statut") {
      let scope;
      switch (excelData[columns[key].label]) {
        case "Collaborateur":
          scope = USERS.COLLAB;
          break;
        case "Externe":
          scope = USERS.EXTERNAL;
          break;
        case "Client":
          scope = USERS.CLIENT;
          break;
        case "Employé":
          scope = USERS.EMPLOYEE;
          break;
        default:
          scope = USERS.COLLAB;
          break;
      }
      result.scope = scope;
    }

    if (type === "employee") {
      result.scope = USERS.EMPLOYEE;
    }

    if (!excelData[columns[key].label]) {
      result[key] = "";
      if (nullKey.indexOf(key) !== -1) {
        result[key] = null;
      }
      if (zeroKey.indexOf(key) !== -1) {
        result[key] = 0;
      }
    } else {
      switch (database.type) {
        case Date: {
          result[key] = parse(
            excelData[columns[key].label],
            "dd/MM/yyyy",
            new Date()
          );
          break;
        }
        case Number: {
          result[key] = Number(excelData[columns[key].label]);
          break;
        }
        default: {
          excelData[columns[key].label].toString();
          if (key === "socialSecurityNumber") {
            result[key] = Number(excelData[columns[key].label]).toString();
          } else result[key] = excelData[columns[key].label].toString();

          break;
        }
      }
    }
  });
  return {
    isValid: isValid,
    errors: errors,
    data: result,
  };
};
