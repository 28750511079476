<template>
  <section class="import-page">
    <Banner>
      <div class="banner-content">
        <MeepIconActionImport class="banner-content__icon" />
        <span class="banner-content__text">{{
          $t("import.banner-title")
        }}</span>
      </div>
    </Banner>
    <PageHeader :has-back="true" :title="$t('import.title')" @back="goBack()" />
    <div class="import-page-content page-layout">
      <div class="import-page-cards">
        <SettingCard
          v-if="isAdmin"
          :buttonText="$t('setting.import-company.import')"
          :content="$t('setting.import-company.content')"
          :title="$t('setting.import-company.title')"
          hasBack
          link="/dashboard/setting/companies/import"
          @back="$router.push('/dashboard/setting/companies/')"
        >
          <MeepIconActionImport class="app-icon" />
          <template #secondButton>
            <md-button
              class="import-page-button"
              href="/templates/meep_entreprises_template.xlsx"
              >{{ $t("setting.import-download-template") }}
            </md-button>
          </template>
          <template #primaryButton>
            <vue-xlsx-table
              button-class-name="md-button md-ripple md-secondary md-raised setting-card-button"
              @on-select-file="handleSelectedFileCompanies"
            >
              <span class="md-small-hide md-raised md-primary">
                {{ $t("import-companies.import-title") }}
              </span>
            </vue-xlsx-table>
          </template>
        </SettingCard>
        <SettingCard
          :buttonText="$t('setting.import-users.import')"
          :content="$t('setting.import-users.content')"
          :title="$t('setting.import-users.title')"
          hasBack
          link="/dashboard/setting/users/import"
          @back="$router.push('/dashboard/setting/users/')"
        >
          <MeepIconActionImport class="app-icon" />
          <template #secondButton>
            <md-button
              class="import-page-button"
              href="/templates/meep_users_template.xlsx"
              >{{ $t("setting.import-download-template") }}
            </md-button>
          </template>
          <template #primaryButton>
            <vue-xlsx-table
              button-class-name="md-button md-ripple md-secondary md-raised setting-card-button"
              @on-select-file="handleSelectedFileUsers"
            >
              <span class="md-small-hide md-raised md-primary">
                {{ $t("import-users.import-title") }}
              </span>
            </vue-xlsx-table>
          </template>
        </SettingCard>
        <SettingCard
          :buttonText="$t('setting.import-users.import')"
          :content="$t('setting.import-employees.content')"
          :title="$t('setting.import-employees.title')"
          hasBack
          link="/dashboard/setting/users/import"
          @back="$router.push('/dashboard/setting/users/')"
        >
          <MeepIconActionImport class="app-icon" />
          <template #secondButton>
            <md-button
              class="import-page-button"
              href="/templates/meep_employees_template.xlsx"
              >{{ $t("setting.import-download-template") }}
            </md-button>
          </template>
          <template #primaryButton>
            <vue-xlsx-table
              button-class-name="md-button md-ripple md-secondary md-raised setting-card-button"
              @on-select-file="handleSelectedFileEmployees"
            >
              <span class="md-small-hide md-raised md-primary">
                {{ $t("import-users.import-title") }}
              </span>
            </vue-xlsx-table>
          </template>
        </SettingCard>
      </div>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner";
import PageHeader from "@/components/PageHeader.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import SettingCard from "@/components/SettingCard";
import MeepIconActionImport from "@/components/icons/MeepIconActionImport.vue";
import {
  buildObject,
  companiesTemplate,
  adminUserTemplate,
  userTemplate,
  employeesTemplate,
} from "@/services/excel";
import { mapActions, mapGetters } from "vuex";
import { USERS } from "@/constants";

export default {
  name: "ImportPage",
  components: {
    Banner,
    SettingCard,
    MeepIconActionImport,
    PageHeader,
  },

  mixins: [HasBackMixin],

  data() {
    return {
      newUsers: [],
      newCompanies: [],
    };
  },

  props: [],

  computed: {
    ...mapGetters(["isAdmin"]),
  },

  mounted() {
    this.SET_IMPORT_ERROR_LINE(false);
    this.SET_IMPORT_USERS([]);
    this.SET_IMPORT_COMPANIES([]);
  },

  methods: {
    ...mapActions([
      "SET_IMPORT_COMPANIES",
      "SET_IMPORT_USERS",
      "SET_IMPORT_ERROR_LINE",
    ]),

    handleSelectedFileCompanies(convertedCompanies) {
      if (!convertedCompanies) {
        this.$toasted.global.AppError({
          message: this.$t("common.import-wrong-format"),
        });
      }

      this.newCompanies = [];

      const columns = companiesTemplate;

      this.newCompanies = convertedCompanies.body.map(company =>
        buildObject(company, columns, "company")
      );

      this.newCompanies = this.newCompanies.map(company => {
        company.name = company.data.name;
        if (company.isValid) {
          company.status = "Ok!";
        } else {
          let message = "";
          company.errors.forEach(error => {
            message += error + ", ";
          });
          this.SET_IMPORT_ERROR_LINE(true);
          company.messageError = message.slice(0, message.length - 2);
        }
        return company;
      });
      this.SET_IMPORT_COMPANIES(this.newCompanies);
      this.$router.push("/dashboard/setting/companies/import");
    },

    async handleSelectedFileUsers(convertedUsers) {
      if (!convertedUsers) {
        this.$toasted.global.AppError({
          message: this.$t("common.import-wrong-format"),
        });
      }
      this.newUsers = [];

      const columns = this.canChangeUserStatus
        ? adminUserTemplate
        : userTemplate;

      this.newUsers = convertedUsers.body.map(user => {
        return buildObject(user, columns, "user");
      });

      this.newUsers = this.newUsers.map(user => {
        user.name = `${user.data.firstname ? user.data.firstname + " " : ""}${
          user.data.lastname ? user.data.lastname : ""
        }`;
        if (this.isJEPA) {
          user.data.scope = USERS.CLIENT;
        }
        if (user.isValid) {
          user.status = "Ok!";
        } else {
          let message = "";
          user.errors.forEach(error => {
            message += error + ", ";
          });
          this.SET_IMPORT_ERROR_LINE(true);
          user.messageError = message.slice(0, message.length - 2);
        }
        return user;
      });
      this.SET_IMPORT_USERS(this.newUsers);
      this.$router.push("/dashboard/setting/users/import");
    },

    async handleSelectedFileEmployees(convertedUsers) {
      if (!convertedUsers) {
        this.$toasted.global.AppError({
          message: this.$t("common.import-wrong-format"),
        });
      }
      this.newUsers = [];

      const columns = employeesTemplate;

      this.newUsers = convertedUsers.body.map(user => {
        return buildObject(user, columns, "employee");
      });

      this.newUsers = this.newUsers.map(user => {
        user.name = `${user.data.firstname ? user.data.firstname + " " : ""}${
          user.data.lastname ? user.data.lastname : ""
        }`;
        if (this.isJEPA) {
          user.data.scope = USERS.CLIENT;
        }
        if (user.isValid) {
          user.status = "Ok!";
        } else {
          let message = "";
          user.errors.forEach(error => {
            message += error + ", ";
          });
          this.SET_IMPORT_ERROR_LINE(true);
          user.messageError = message.slice(0, message.length - 2);
        }
        return user;
      });
      this.SET_IMPORT_USERS(this.newUsers);
      this.$router.push({
        path: "/dashboard/setting/users/import/",
        query: { type: "employee" },
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .import-page {
    .page-header__item{
      margin: 0;
    }
    &-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 24px;
      margin-bottom: 105px;
    }

    &-card-text {
      padding: 0 5px 35px 5px;
    }

    &-card-title {
      font-size: 36px;
    }

    &-button.md-button {
      border-radius: 9px;
      border: solid 2px var(--primary, #000);
      color: var(--primary);
    }

    .app-icon {
      width: 50px;
      height: 50px;
      margin-top: 30px;
      @include for-lg {
        width: 90px;
        height: 90px;
      }
    }

    .banner {
      &-content {
        &__icon {
          width: 60px;
          height: 60px;
        }
      }
    }

    &-content {
      height: 100%;

      .setting-card {
        width: auto;

        .setting-card-text {
          width: 80%;
          font-size: toRem(12);
          line-height: 1.17;
          letter-spacing: normal;
          padding: 0;
          font-family: var(--font-x);
          margin: toRem(13) 0 toRem(33) 0;
          margin-bottom: toRem(32);
          @include for-lg {
            font-size: toRem(20);
            letter-spacing: -0.5px;
            line-height: toRem(23);
            margin-bottom: toRem(40);
          }
        }
      }

      .app-icon {
        margin: 0;
      }
    }
  }
}
</style>
